import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

import {LoaderState} from '../models';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() {
  }

  public show() {
    this.loaderSubject.next({show: true});
  }

  public hide() {
    this.loaderSubject.next({show: false});
  }
}
