import {Injectable} from '@angular/core';

import {BackendService} from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class OlxUrlService {
  public clientId = 200003;
  public state = 'main';

  constructor(
    private backend: BackendService
  ) {
  }

  public getLoginUrl(state?): string {
    const appState = state ? JSON.stringify(state) : this.state;
    // Join all pieces to one complete URL
    return 'https://www.olx.ua/oauth/authorize/?client_id=' + this.clientId + '&response_type=code&state=' + appState + '&scope=read+write+v2&redirect_uri=' + this.getRedirectUri();
  }

  public getRedirectUri() {
    if (window.location.origin.indexOf('olx-business') > -1) {
      return 'https://foodsets.olx-business.com/login/olx';
    } else {
      // Set a redirectUri value based on API_URL with environment binding
      return this.backend.API_URL.replace('api', 'foodsets') + '/login/olx';
    }
  }
}

