import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {FoodsetsService} from '../../services/foodsets.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public isFoodsetPage = false;
  private footerSubscription: Subscription;

  constructor(
    private foodsetService: FoodsetsService
  ) {
    this.footerSubscription = this.foodsetService.footerWhiteSubscribtion().subscribe((state) => {
      this.isFoodsetPage = state;
    });
  }

  ngOnInit(): void {
  }

}
