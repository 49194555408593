<section class="page-section iframe-section">
  <div class="container">
    <h1 class="page-header">Продуктовий набір {{foodSetId}}</h1>
    <div class="row iframe-section-blocs">
      <div class="col-sm-8">
        <div class="iframe-section-blocs-item">
          <h4>Купуй продукти онлайн просто та безпечно</h4>
          <div class="row iframe-section-blocs-item-how">
            <div class="col-sm-4 iframe-section-blocs-item-how-item">
              <img src="../../../assets/img/how-it-works-1.png" alt="Мапа">
              <p class="iframe-section-blocs-item-how-item-title">Обери продуктовий набір</p>
<!--              <p class="iframe-section-blocs-item-how-item-text">Specify your contact information and select a Nova-->
<!--                Poshta ffice.</p>-->
            </div>
            <div class="col-sm-4 iframe-section-blocs-item-how-item">
              <img src="../../../assets/img/how-it-works-2.png" alt="Карта">
              <p class="iframe-section-blocs-item-how-item-title">Вкажи адресу доставки та оплати</p>
<!--              <p class="iframe-section-blocs-item-how-item-text">Specify your contact information and select a Nova-->
<!--                Poshta ffice.</p>-->
            </div>
            <div class="col-sm-4 iframe-section-blocs-item-how-item">
              <img src="../../../assets/img/how-it-works-3.png" alt="НоваПошта">
              <p class="iframe-section-blocs-item-how-item-title">Кур’єр Нова пошта доставить його, куди забажаєш</p>
<!--              <p class="iframe-section-blocs-item-how-item-text">Specify your contact information and select a Nova-->
<!--                Poshta ffice.</p>-->
            </div>
          </div>
        </div>
        <div class="iframe-section-blocs-item iframe-section-blocs-item-iframe" *ngIf="iFrameUrl">
          <iframe [src]="iFrameUrl" id="iframe" width="100%" height="{{iFrameHeight}}" frameborder="0"
                  webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="iframe-section-blocs-item iframe-section-blocs-item-set">
          <img [src]="foodSetImg" alt="Продуктовий набір {{foodSetId}}" class="iframe-section-blocs-item-set-img">
          <div class="iframe-section-blocs-item-set-price">
            <h4>Набір {{foodSetId}} ({{foodSetNumber}}шт.)</h4>
            <p class="iframe-section-blocs-item-set-price-text">{{getSetPrice(foodSetId)}} ₴</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
