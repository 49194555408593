import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';

import {OlxService} from './services/olx.service';
import {UserService} from './services/user.service';
import {LoadingService} from './services/loading.service';

import {LoaderState} from './models';
import {FoodsetsService} from './services/foodsets.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private loadingSubscription: Subscription;
  public isLoading = false;

  constructor(
    private olxService: OlxService,
    private userService: UserService,
    private loadingService: LoadingService,
    private foodsetsService: FoodsetsService
  ) {
    this.loadingSubscription = this.loadingService.loaderState
      .subscribe((state: LoaderState) => {
        this.isLoading = state.show;
      });
  }

  ngOnInit(): void {
    this.olxService.getFoodSets().then((r) => {
      if (r && r.status === 1) {
        if (r.data.sets && r.data.sets.length > 0) {
          this.foodsetsService.setFoodSetsList(r.data.sets);
        }
      }
    });
    this.olxService.getUserData().then((r) => {
      if (r && r.status === 1) {
        if (!this.userService.isEmptyUserData(r.data)) {
          this.userService.setUserData(r.data);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }
}
