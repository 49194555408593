import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {PurchaseService} from '../../services/purchase.service';
import {FoodsetsService} from '../../services/foodsets.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  public purchase: any;

  constructor(
    private purchaseService: PurchaseService,
    private router: Router,
    private foodsetsService: FoodsetsService
  ) {
    this.foodsetsService.setFooterWhite(true);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.purchase = this.purchaseService.getPurchase();
      if (!this.purchase) {
        this.router.navigate(['/']).then(null);
      }
    }, 100);
  }

  public getDeliveryDate(date: string): string {
    return date.split('-').reverse().join('.');
  }

}
