import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {RequestBody, ResponseErrorBody, ResponseSuccessBody} from '../models';

@Injectable({
  providedIn: 'root'
})

export class BackendService {
  public readonly API_URL: string;

  constructor(private http: HttpClient) {
    this.API_URL = this.getApiUrl();
  }

  public getApiUrl() {
    if (window.location.origin.indexOf('localhost') > -1) {
      return 'https://api.stage.uapay.ua';
    } else if (window.location.origin.indexOf('alpha') > -1) {
      return 'https://api.alpha.uapay.ua';
    } else if (window.location.origin.indexOf('beta') > -1) {
      return 'https://api.beta.uapay.ua';
    } else if (window.location.origin.indexOf('demo') > -1) {
      return 'https://api.demo.uapay.ua';
    } else if (window.location.origin.indexOf('zero') > -1) {
      return 'https://api.zero.uapay.ua';
    } else if (window.location.origin.indexOf('stage') > -1) {
      return 'https://api.stage.uapay.ua';
    } else {
      return 'https://api.uapay.ua';
    }
  }

  public request(ENDPOINT: string, BODY: RequestBody): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.API_URL + ENDPOINT, BODY).subscribe((response: ResponseSuccessBody) => {
        resolve(response);
      }, (error: ResponseErrorBody) => {
        reject(error.error);
      });
    });
  }
}
