import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

import {FoodsetsService} from '../../services/foodsets.service';
import {OlxService} from '../../services/olx.service';
import {PostMessageService} from '../../services/postMessage.service';
import {ThreeDsService} from '../../services/3ds.service';
import {ErrorHandlerService} from '../../services/error-handler.service';
import {PurchaseService} from '../../services/purchase.service';
import {WindowService} from '../../services/window.sevice';

@Component({
  selector: 'app-foodset',
  templateUrl: './foodset.component.html',
  styleUrls: ['./foodset.component.scss']
})
export class FoodsetComponent implements OnInit {
  private minNumbersOfSets = 1;
  private maxNumbersOfSets = 3;
  public foodSetId = this.route.snapshot.params.id;
  public foodSetNumber = this.route.snapshot.queryParams.number ? +this.route.snapshot.queryParams.number : 1;
  public foodSetImg = '';
  public iFrameUrl;
  public iFrameHeight = '100%';

  constructor(
    private route: ActivatedRoute,
    private foodsetsService: FoodsetsService,
    private router: Router,
    private olxService: OlxService,
    private sanitizer: DomSanitizer,
    private post: PostMessageService,
    private threeDsService: ThreeDsService,
    private errorHandler: ErrorHandlerService,
    private purchaseService: PurchaseService,
    private windowService: WindowService
  ) {
    this.post.getMessage.subscribe((data) => {
      if (data.action === 'changeSize') {
        this.iFrameHeight = data.size.height + 48;
      }
      if (data.action === '3dsModal') {
        this.threeDsService.open3Ds(data.data);
      }
      if (data.frameName === 'payment' && data.name === 'conformShow') {
        this.threeDsService.close3Ds();
      }
      if (data.action === 'buyerCard') {
        if (data.status === 'error') {
          const errorMessage = typeof data.errorMessage === 'object' ? data.errorMessageLoc : data.errorMessage;
          this.windowService.nativeWindow.ninjaClickTrigger('foodsets_payment_error', errorMessage);
          this.errorHandler.handle(errorMessage);
        }
        if (data.status === 'rejected') {
          if (data.data.errorCode !== '3DS_CANCELED') {
            this.errorHandler.handle(data.data.errorMessage);
          }
        }
        if (data.status === 'ok') {
          // tslint:disable-next-line:max-line-length
          this.windowService.nativeWindow.ninjaClickTrigger('foodsets_payment_finish', 'success', 'foodsets', (data.data.amount / 100).toString(), data.data.setCount);
          this.router.navigate(['payment/success']).then(() => {
            window.scrollTo(0, 0);
            this.purchaseService.setPurchase(data.data);
          });
        }
      }
      if (data.action === 'ninjaTrigger') {
        if (data.data) {
          if (data.status === 'foodsets_delivery_date_select') {
            this.windowService.nativeWindow.ninjaClickTrigger(data.status, data.data.type);
            return;
          }
          if (data.status === 'foodsets_payment_start') {
            // tslint:disable-next-line:max-line-length
            this.windowService.nativeWindow.ninjaClickTrigger(data.status, data.data.type, data.data.name, data.data.amount.toString(), data.data.setCount);
            return;
          }
        }
        this.windowService.nativeWindow.ninjaClickTrigger(data.status);
      }
    });
    this.foodsetsService.setFooterWhite(true);
    this.foodSetImg = this.foodsetsService.findCurrentFoodSet(this.foodSetId) ? '../../../assets/img/food-set-' + this.foodSetId + '.png' : '';
    this.checkNumberOfSets();
  }

  ngOnInit(): void {
    if (!this.foodsetsService.findCurrentFoodSet(this.foodSetId)) {
      this.router.navigate(['/']).then(null);
    } else {
      this.olxService.getIframeUrl({
        params: {
          setId: +this.foodSetId,
          setCount: +this.foodSetNumber
        }
      }).then((response) => {
        if (response && response.status === 1) {
          // Stages and PROD
          this.iFrameUrl = response.data.iFrameUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(response.data.iFrameUrl) : null;
          // LOCAL ONLY
          // this.iFrameUrl = response.data.iFrameUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(response.data.iFrameUrl.replace('https://iframe-foodsets.alpha.uapay.ua/', 'http://localhost:3000/')) : null;
        }
      });
    }
  }

  private checkNumberOfSets() {
    if (this.foodSetNumber > this.maxNumbersOfSets) {
      this.foodSetNumber = this.maxNumbersOfSets;
    } else if (this.foodSetNumber < this.minNumbersOfSets) {
      this.foodSetNumber = this.minNumbersOfSets;
    }
  }

  public getSetPrice(fsid: string) {
    return this.foodsetsService.getSetPrice(fsid);
  }

}
