import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';

import {UserService} from '../services/user.service';
import {OlxService} from '../services/olx.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private olxService: OlxService,
    private router: Router
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.olxService.getUserData().then((r) => {
      if (r && r.status === 1) {
        if (!this.userService.isEmptyUserData(r.data)) {
          return true;
        } else {
          this.router.navigate(['/']).then(null);
          return false;
        }
      }
    });
  }
}
