import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {Subscription} from 'rxjs';

import {OlxService} from '../../../services/olx.service';
import {ErrorHandlerService} from '../../../services/error-handler.service';
import {UserService} from '../../../services/user.service';
import {WindowService} from '../../../services/window.sevice';

import {ResponseSuccessBody} from '../../../models';

@Component({
  selector: 'app-olx',
  templateUrl: './olx.component.html',
  styleUrls: ['./olx.component.scss']
})
export class OlxComponent implements OnInit {
  private querySubscription: Subscription;
  private olxLoginCode: string;
  private olxLoginError: string;
  private olxLoginErrorDescription: string;
  private state: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private olxService: OlxService,
    private errorHandleService: ErrorHandlerService,
    private userService: UserService,
    private windowService: WindowService
  ) {
    this.querySubscription = route.queryParams.subscribe(
      (queryParam: any) => {
        // If olx login is successful
        this.olxLoginCode = queryParam.code ? queryParam.code : null;
        // If not
        this.olxLoginError = queryParam.error ? queryParam.error : null;
        this.olxLoginErrorDescription = queryParam.error_description ? queryParam.error_description : null;
        // State must be always with other auth query params
        this.state = queryParam.state ? queryParam.state !== 'main' ? JSON.parse(queryParam.state) : queryParam.state : null;

        if (!this.olxLoginCode && !this.olxLoginError) {
          this.router.navigate(['/']).then();
          return;
        }

        if (this.olxLoginError) {
          this.router.navigate(['/']).then(() => {
            this.errorHandleService.handle(this.olxLoginErrorDescription);
          });
          return;
        }

        if (this.olxLoginCode && this.olxLoginCode !== '') {
          this.olxService.login(this.olxLoginCode).then((responseLogin: ResponseSuccessBody) => {
            if (responseLogin && responseLogin.status === 1) {
              this.olxService.getUserData().then((responseUserData: ResponseSuccessBody) => {
                if (responseUserData && responseUserData.status === 1) {
                  this.userService.setUserData(responseUserData.data);
                  this.windowService.nativeWindow.ninjaClickTrigger('foodsets_login_finish');
                  // If state is Object with id param
                  if (this.state.id) {
                    this.router.navigate(['foodset/' + this.state.id], {
                      queryParams: {
                        number: this.state.setCount ? this.state.setCount : 1
                      },
                      queryParamsHandling: 'merge'
                    }).then();
                  } else {
                    this.router.navigate(['/']).then();
                  }
                }
              });
            }
          });
        }
      }
    );
  }

  ngOnInit(): void {
  }

}
