import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {OlxUrlService} from '../../services/olx-url.service';
import {WindowService} from '../../services/window.sevice';

@Component({
  selector: 'app-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{title}}</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="alert" [class]="alertStatus" role="alert">
        {{modalContent}}
      </div>
    </div>
    <div class="modal-footer">
      <button *ngIf="showLoginBtn" type="button" class="btn btn-primary" (click)="login()">Авторизуватись</button>
      <button type="button" class="btn btn-default" (click)="bsModalRef.hide();">Закрити</button>
    </div>
  `
})

export class ModalContentComponent implements OnInit {
  title: string;
  modalContent: string;
  alertStatus: string;
  showLoginBtn = false;
  setId;
  setCount;
  reloadPage: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public olxUrlService: OlxUrlService,
    public modalService: BsModalService,
    private windowService: WindowService
  ) {
    this.modalService.onHidden.subscribe((reason: string) => {
      this.reloadPage ? location.reload() : console.log(reason);
    });
  }

  ngOnInit() {
  }

  public login() {
    const state = this.getState();
    this.windowService.nativeWindow.ninjaClickTrigger('foodsets_login_start');
    window.location.href = this.olxUrlService.getLoginUrl(state);
  }

  public getState() {
    let stateObj;

    if (this.setId) {
      stateObj = {
        id: this.setId
      };

      if (this.setCount) {
        stateObj = {...stateObj, setCount: this.setCount};
      }
    }

    return stateObj;
  }
}
