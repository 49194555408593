<nav class="navbar navbar-black sticky-top">
  <div class="container-fluid">
    <a class="navbar-brand" href>
      <img src="../assets/img/logos/olx-logo-white.svg" alt="OLX логотип">
    </a>
    <ul class="nav justify-content-end">
      <li class="nav-item">
        <a class="nav-link" [href]="login.olx.url" *ngIf="!isUserLogged()" (click)="triggerLoginStart()">
          <img src="../assets/img/icons/icon-user-photo.png" alt="Фотографія користувача"> Мій профіль
        </a>
        <div class="dropdown" *ngIf="isUserLogged()">
          <span dropdown>
            <a href class="nav-link" id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown">
              <img src="../assets/img/icons/icon-user-photo.png" alt="Фотографія користувача"> Мій профіль
              <img src="../assets/img/icons/icon-arrow-down.svg" alt="Меню" style="margin-left: 10px">
            </a>
            <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                role="menu" aria-labelledby="basic-link">
              <li>
                <span class="dropdown-item">
                  <img [src]="userData.avatar" alt="Фотографія користувача" class="user-data-avatar">
                  {{userContact}}
                </span>
                <span class="dropdown-item" (click)="logout()">Вихід</span>
              </li>
            </ul>
          </span>
        </div>
      </li>
    </ul>
  </div>
</nav>
