<header>
  <div class="container d-flex align-items-center flex-column">
    <div class="row">
      <div class="col-md-6 header-content">
        <h1>Купуй продуктові набори не виходячи з дому</h1>
        <p>
          Кур’єрська доставка продуктових наборів <span style="white-space: nowrap">Еко Маркет</span> по Києву з <span style="white-space: nowrap">Нова пошта</span>
        </p>
        <div class="row partners-logo">
          <div class="col-3">
            <img src="../assets/img/logos/ecomarket-logo.svg" alt="Логотип екомаркету">
          </div>
          <div class="col-3">
            <img src="../assets/img/logos/uapay-logo.svg" alt="Логотип UaPay">
          </div>
          <div class="col-3">
            <img src="../assets/img/logos/novaposhta-logo.svg" alt="Логотип новапошта">
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <img src="../assets/img/header-food-set.png" alt="Набір продуктів" class="full-width">
      </div>
    </div>
  </div>
</header>

<section class="page-section food-sets">
  <div class="container-fluid">
    <h2 class="page-section-heading text-center">Оберіть набір</h2>
    <div class="row">
      <div class="col-md-6 col-lg-4 mb-5">
        <div class="card">
          <img src="../assets/img/food-set-1.png" class="card-img-top" alt="Набір 1">
          <div class="row m-0">
            <div class="col-md-6 text-center">
              <h3 class="card-title">Набір 1</h3>
              <p class="card-subtitle">{{getSetPrice('1')}} ₴</p>
            </div>
            <div class="col-md-6 align-self-center">
              <div class="number-of-sets text-center">
                <span (click)="decreaseNumberOfSets('1'); checkValue('1');" [class]="numberOfSets[1].prev">-</span>
                <input type="tel" class="text-center number-of-sets-input" maxlength="2" value="1" OnlyNumber="true" [(ngModel)]="numberOfSets[1].value" (ngModelChange)="checkValue('1')" autocomplete="new-password">
                <span (click)="increaseNumberOfSets('1'); checkValue('1');" [class]="numberOfSets[1].next">+</span>
              </div>
              <a href class="btn btn-outline-buy" (click)="openSetPage($event, '1')">Купити</a>
            </div>
          </div>
          <div class="card-body">
            <div class="card-text">
              <h4><img src="../assets/img/icons/icon-info.svg" alt="Інформація">Список продуктів</h4>
            </div>
            <ol>
              <li>Цукор Саркара пісок 1кг п/п</li>
              <li>Борошно Хуторок пшеничне в/г 1кг</li>
              <li>Олія соняшникова Золота рафінована дезодорована виморожена 820мл ПЕТ</li>
              <li>Макаронні вироби La Pasta спіральки 400г</li>
              <li>Крупа Хуторок гречана ядриця 800г</li>
              <li>Чай Принцеса Нурі високогірний 25пак*2г (50г)</li>
              <li>Папір туалетний Обухів 65м 2 шт.</li>
              <li>Хліб КИЇВХЛІБ пшеничний "Сімейний" половина, нарізаний 300г</li>
              <li>Печиво Roshen До кави з ароматом пряженого молока 185г</li>
              <li>Молоко Селянське суперпастеризоване 2,5% 900г ТetraFino</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 mb-5">
        <div class="card">
          <img src="../assets/img/food-set-2.png" class="card-img-top" alt="Набір 2">
          <div class="row m-0">
            <div class="col-md-6 text-center">
              <h3 class="card-title">Набір 2</h3>
              <p class="card-subtitle">{{getSetPrice('2')}} ₴</p>
            </div>
            <div class="col-md-6 align-self-center">
              <div class="number-of-sets text-center">
                <span (click)="decreaseNumberOfSets('2'); checkValue('2');" [class]="numberOfSets[2].prev">-</span>
                <input type="tel" class="text-center number-of-sets-input" maxlength="2" value="1" OnlyNumber="true" [(ngModel)]="numberOfSets[2].value" (ngModelChange)="checkValue('2')" autocomplete="new-password">
                <span (click)="increaseNumberOfSets('2'); checkValue('2');" [class]="numberOfSets[2].next">+</span>
              </div>
              <a href class="btn btn-outline-buy" (click)="openSetPage($event, '2')">Купити</a>
            </div>
          </div>
          <div class="card-body">
            <div class="card-text">
              <h4><img src="../assets/img/icons/icon-info.svg" alt="Інформація">Список продуктів</h4>
            </div>
            <ol>
              <li>Цукор Саркара пісок 1кг п/п</li>
              <li>Борошно Хуторок пшеничне в/г 1кг</li>
              <li>Олія Олейна пресова соняшникова рафінована дезодорована виморожена 0,85л</li>
              <li>Макаронні вироби La Pasta Спагетті 400г</li>
              <li>Крупа Хуторок гречана ядриця 800г</li>
              <li>Чай Принцеса Нурі високогірний 25пак 2г</li>
              <li>Папір туалетний Обухів 2 рулони 65м 2 шт.</li>
              <li>Хліб КИЇВХЛІБ пшеничний "Сімейний" половина, нарізаний 300г</li>
              <li>Печиво Roshen До кави з ароматом пряженого молока 185г</li>
              <li>Молоко Селянське суперпастеризоване 2,5% 900г ТetraFino</li>
              <li>Пластівці Панська Нива вівсяні ніжні м'яка упаковка 500г</li>
              <li>Крупа пшенична Хуторок Полтавська №3 800г</li>
              <li>Паштет ОНІСС Український 240г ж/б</li>
              <li>Вафлі Світоч Артек 80г - 3 шт.</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4 mb-5">
        <div class="card">
          <img src="../assets/img/food-set-3.png" class="card-img-top" alt="Набір 3">
          <div class="row m-0">
            <div class="col-md-6 text-center">
              <h3 class="card-title">Набір 3</h3>
              <p class="card-subtitle">{{getSetPrice('3')}} ₴</p>
            </div>
            <div class="col-md-6 align-self-center">
              <div class="number-of-sets text-center">
                <span (click)="decreaseNumberOfSets('3'); checkValue('3');" [class]="numberOfSets[3].prev">-</span>
                <input type="tel" class="text-center number-of-sets-input" maxlength="2" value="1" OnlyNumber="true" [(ngModel)]="numberOfSets[3].value" (ngModelChange)="checkValue('3')" autocomplete="new-password">
                <span (click)="increaseNumberOfSets('3'); checkValue('3');" [class]="numberOfSets[3].next">+</span>
              </div>
              <a href class="btn btn-outline-buy" (click)="openSetPage($event, '3')">Купити</a>
            </div>
          </div>
          <div class="card-body">
            <div class="card-text">
              <h4><img src="../assets/img/icons/icon-info.svg" alt="Інформація">Список продуктів</h4>
            </div>
            <ol>
              <li>Цукор Саркара пісок 1кг п/п</li>
              <li>Олія Олейна пресова соняшникова рафінована дезодорована виморожена 0,85л</li>
              <li>Макаронні вироби Granaria Бантики Farfalle 500г п/е</li>
              <li>Борошно Хуторок 2кг паперовий пакет</li>
              <li>Чай Грінфілд голден цейлон 25пак 2г</li>
              <li>Крупа гречана "Сквирянка" ядриця швидкорозварювана 800г</li>
              <li>Пластівці Новоукраїнка Вівсяні 800г</li>
              <li>Папір туалетний Перший Ряд білий 4шт</li>
              <li>Серветки вологі Softy для всієї родини 60шт</li>
              <li>Серветки ЕКО-номка одношарові 60 шт</li>
              <li>Майонез Торчин Європейський 72 % 300г</li>
              <li>Сардина Аквамарин натуральна з додаванням олії 230г ж/б №6</li>
              <li>Молоко Селянське суперпастеризоване 2,5% 900г ТetraFino</li>
              <li>Кава Gimoka натуральна мелена Gran Festa 250г</li>
              <li>Цукерки Конті Шоколадні історії Джек вагові</li>
              <li>Кабаноси Дмитрук ORIGINAL свинні з додаванням мяса курки 100г полімерна плівка</li>
              <li>Сік Сандора Апельсиновий 0,95л</li>
              <li>Хліб КИЇВХЛІБ Тост злаковий 350г</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="page-section sets-stepper">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 stepper-text">
        Купуй продукти онлайн просто та безпечно
      </div>
      <div class="col-md-8 flex-center">
        <div class="row full-width steps-wrapper">
          <div class="col-md-3">
            <img src="../assets/img/step-1.svg" alt="Продуктовий набір">
            <p>Обери продуктовий набір</p>
          </div>
          <div class="col-md-3">
            <img src="../assets/img/step-2.svg" alt="Вкажи адресу доставки">
            <p>Вкажи адресу доставки</p>
          </div>
          <div class="col-md-3">
            <img src="../assets/img/step-3.svg" alt="Оплати">
            <p>Оплати</p>
          </div>
          <div class="col-md-3">
            <img src="../assets/img/step-4.svg" alt="Кур’єр Нова пошта доставить його, куди забажаєте">
            <p>Кур’єр Нова пошта доставить його, куди забажаєте</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="page-section additional-promotion">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-8 additional-promotion-wrapper">
        <h2 class="page-section-heading">Додаткові умови</h2>
        <div class="additional-promotion-text">
          <b>Нова пошта</b> здійснює безпечну кур'єрську доставку продуктових наборів Еко Маркет по Києву.
          <br>
          Вартість доставки будь-якого набору - 70 грн.
          <br><br>
          <b>Еко Маркет</b> відповідає за наявність та якість продуктових наборів.
          В наборах вказано базові товарні позиції, у разі їх відсутності на момент упаковки позиції може бути замінено альтернативними товарами зі схожими характеристиками, наприклад, ячна крупа замість пшеничної, м'ясні консерви замість рибних і навпаки.
          <br><br>
          <b><a href="https://uapay.ua" target="_blank">UAPAY</a></b> забезпечує онлайн оплату продуктових наборів та безпеку обробки даних вашої картки.
          <br><br>
          <a href="https://help.olx.ua/hc/uk/articles/360013436160" target="_blank">Правила акції</a>
        </div>
      </div>
      <div class="col-md-4 additional-promotion-bg p-0">
        <img src="../assets/img/additional-promotion-bg.png" alt="Продукти">
      </div>
    </div>
  </div>
</section>
