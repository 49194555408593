export const environment = {
  production: getEnvironment()
};

function getEnvironment() {
  if ((window.location.origin.indexOf('localhost')
    || window.location.origin.indexOf('alpha')
    || window.location.origin.indexOf('beta')
    || window.location.origin.indexOf('demo')
    || window.location.origin.indexOf('zero')
    || window.location.origin.indexOf('stage')) > -1) {
    return 'DEV';
  } else {
    return 'PROD';
  }
}
