<footer class="footer text-center" [ngClass]="{'bg-white': isFoodsetPage}">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4 class="text-uppercase mb-4"><img src="../assets/img/logos/olx-logo-black.svg"></h4>
        <p class="mb-0 footer-text">
          Усі онлайн-оголошення України на OLX - тут ви знайдете те, що шукали! Натиснувши на кнопку "Подати оголошення", ви зможете розмістити оголошення на будь-яку тематику легко й швидко.
          <br>За допомогою сервісу OLX ви зможете купити чи продати практично все, що завгодно.
        </p>
      </div>
    </div>
  </div>
</footer>
