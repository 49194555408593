import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class PostMessageService {
  public getMessage = new Subject<any>();

  constructor() {
    if (window.addEventListener) {
      window.addEventListener('message', (event: { data: any, origin: string }) => {
        const environmentRegExp = /[https://]+(olx|iframe-foodsets|foodsets|uapay)+[\.a-zA-Z-\.]+(uapay.ua|ua|com)/g;

        console.log('  -=-=-=-=- iFrame send mess', event.data, event.origin);

        if (!environmentRegExp.test(event.origin) && event.origin.indexOf('localhost') === -1) {
          return;
        }

        if (event.data) {
          if (event.data.frameName === 'payment' && event.data.name === 'conformShow') {
            this.getMessageEvent(event.data);
            this.postMessageEvent('3dsModal', 'ok');
            return;
          }
          try {
            const data = JSON.parse(event.data);
            this.getMessageEvent(data);
          } catch (e) {
            console.log('=-=- message error', e);
            // todo errorhendler
          }
        }
      });
    }
  }

  public getMessageEvent(message) {
    this.getMessage.next(message);
  }

  public postMessageEvent(action, status, data?) {
    const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
      input !== null && input.tagName === 'IFRAME';
    const iframe = document.getElementById('iframe');
    const postData = {
      action,
      status
    };
    if (status === 'error') {
      postData['errorCode'] = data.errorCode;
      postData['errorMessage'] = data.errorMessage;
      postData['errorMessageLoc'] = data.errorCode;
    } else {
      postData['data'] = data;
    }
    if (isIFrame(iframe) && iframe.contentWindow) {
      iframe.contentWindow.postMessage(JSON.stringify({...postData}), '*');
    }
  }
}
