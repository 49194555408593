import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {UserService} from '../../services/user.service';
import {OlxService} from '../../services/olx.service';
import {OlxUrlService} from '../../services/olx-url.service';
import {WindowService} from '../../services/window.sevice';

import {UserDataModel} from '../../models';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  public login = {
    olx: {
      url: this.olxUrlService.getLoginUrl()
    }
  };
  public userContact;
  public userData: UserDataModel = {
    name: null,
    phone: null,
    email: null,
    avatar: null
  };

  constructor(
    private userService: UserService,
    private olxService: OlxService,
    private olxUrlService: OlxUrlService,
    private router: Router,
    private windowService: WindowService
  ) {
    // Subscribe to UserData from login
    this.userService.userDataSubscription().subscribe((userData) => {
      this.userData.name = userData.name;
      this.userData.phone = userData.phone;
      this.userData.email = userData.email;
      this.userData.avatar = userData.avatar ? userData.avatar : '../../../assets/img/user-default-avatar.svg';
      this.userContact = userData.name ? userData.name : userData.phone ? userData.phone : userData.email ? userData.email : '';
    });
  }

  ngOnInit(): void {
  }

  public isUserLogged() {
    return this.userService.isUserLogged();
  }

  public logout() {
    this.olxService.logout().then((response) => {
      if (response && response.status === 1) {
        this.router.navigate(['/']).then(() => {
          this.userService.setUserData({});
        });
      }
    });
  }

  public triggerLoginStart() {
    this.windowService.nativeWindow.ninjaClickTrigger('foodsets_login_start');
  }

}
