import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {LocationStrategy, Location, PathLocationStrategy} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ModalModule} from 'ngx-bootstrap/modal';

import {AppComponent} from './app.component';
import {NavigationComponent} from './components/navigation/navigation.component';
import {FooterComponent} from './components/footer/footer.component';
import {LandingComponent} from './components/landing/landing.component';
import {OlxComponent} from './components/login/olx/olx.component';
import {ModalContentComponent} from './components/modal/modal.component';
import {LoaderComponent} from './components/loader/loader.component';
import {FoodsetComponent} from './components/foodset/foodset.component';
import {TreeDsComponent} from './components/modal/3dsModal.component';
import {PaymentSuccessComponent} from './components/payment-success/payment-success.component';

import {OnlyNumberDirective} from './directives/numbers-only.directive';

import {BackendService} from './services/backend.service';
import {PostMessageService} from './services/postMessage.service';
import {WindowService} from './services/window.sevice';

import {AuthInterceptor} from './interceptors/auth.interceptor';
import {LoaderInterceptor} from './interceptors/loader.interceptor';

import {AuthGuard} from './guards/auth.guard';

const appRoutes: Routes = [
  {path: '', pathMatch: 'full', component: LandingComponent},
  {
    path: 'login', children: [
      {
        path: '', pathMatch: 'full', redirectTo: '/'
      },
      {
        path: 'olx', component: OlxComponent
      }
    ]
  },
  {
    path: 'foodset/:id', pathMatch: 'full', component: FoodsetComponent, canActivate: [AuthGuard]
  },
  {
    path: 'payment/success', pathMatch: 'full', component: PaymentSuccessComponent, canActivate: [AuthGuard]
  },
  {path: '**', component: LandingComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    LandingComponent,
    OnlyNumberDirective,
    OlxComponent,
    ModalContentComponent,
    LoaderComponent,
    FoodsetComponent,
    TreeDsComponent,
    PaymentSuccessComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    HttpClientModule
  ],
  providers: [
    Location,
    AuthGuard,
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    BackendService,
    PostMessageService,
    WindowService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
