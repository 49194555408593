import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FoodsetsService {
  // Footer color Subject
  public $footerWhite: Subject<any> = new Subject<any>();
  // FoodsetsList by default
  public foodsetsList = [
    {
      id: 1,
      price: 21500
    },
    {
      id: 2,
      price: 28900
    },
    {
      id: 3,
      price: 54900
    }
  ];

  constructor() {
  }

  public setFoodSetsList(list) {
    this.foodsetsList = list;
  }

  public getSetPrice(foodSetId: string) {
    return this.findCurrentFoodSet(foodSetId) ? this.findCurrentFoodSet(foodSetId).price / 100 : null;
  }

  public findCurrentFoodSet(FsId) {
    return this.foodsetsList ? this.foodsetsList.filter((s) => s.id === +FsId)[0] : null;
  }

  // Methods for footer color, main/foodsets pages

  public setFooterWhite(state): void {
    this.$footerWhite.next(state);
  }

  public footerWhiteSubscribtion(): Observable<any> {
    return this.$footerWhite.asObservable();
  }

}
