import {Injectable} from '@angular/core';

import {ResponseSuccessBody} from '../models';

import {BackendService} from './backend.service';
import {ErrorHandlerService} from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class OlxService {
  private api: any;

  constructor(
    private backend: BackendService,
    private errorHandleService: ErrorHandlerService
  ) {
    // API endpoints list
    this.api = {
      login: '/api/olxfoodsets/users/login',
      logout: '/api/olxfoodsets/users/logout',
      getUserData: '/api/olxfoodsets/users/me',
      getFoodSets: '/api/olxfoodsets/sets/list',
      createPurchase: '/api/olxfoodsets/purchases/create'
    };
  }

  public login(code: string): Promise<any> {
    const body = {
      params: {
        code
      }
    };
    return this.backend.request(this.api.login, {...body})
      .then((responseBody: ResponseSuccessBody) => {
        if (responseBody.hasOwnProperty('error')) {
          this.errorHandleService.handle(responseBody.error.message, true);
          return;
        }
        return responseBody;
      }, (error) => {
        this.errorHandleService.handle(error, true);
      });
  }

  public getFoodSets(): Promise<any> {
    return this.backend.request(this.api.getFoodSets, {})
      .then((responseBody: ResponseSuccessBody) => {
        if (responseBody.hasOwnProperty('error')) {
          this.errorHandleService.handle(responseBody.error.message, true);
          return;
        }
        return responseBody;
      }, (error) => {
        this.errorHandleService.handle(error, true);
      });
  }

  public getUserData(): Promise<any> {
    return this.backend.request(this.api.getUserData, {})
      .then((responseBody: ResponseSuccessBody) => {
        if (responseBody.hasOwnProperty('error')) {
          this.errorHandleService.handle(responseBody.error.message, true);
          return;
        }
        return responseBody;
      }, (error) => {
        this.errorHandleService.handle(error, true);
      });
  }

  public getIframeUrl(body): Promise<any> {
    return this.backend.request(this.api.createPurchase, {...body})
      .then((responseBody: ResponseSuccessBody) => {
        if (responseBody.hasOwnProperty('error')) {
          this.errorHandleService.handle(responseBody.error.message, true);
          return;
        }
        return responseBody;
      }, (error) => {
        this.errorHandleService.handle(error, true);
      });
  }

  public logout(): Promise<any> {
    return this.backend.request(this.api.logout, {})
      .then((responseBody: ResponseSuccessBody) => {
        if (responseBody.hasOwnProperty('error')) {
          this.errorHandleService.handle(responseBody.error.message, true);
          return;
        }
        return responseBody;
      }, (error) => {
        this.errorHandleService.handle(error, true);
      });
  }
}
