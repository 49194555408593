<section class="page-section payment-success">
  <div class="container" *ngIf="purchase">
    <div class="row">
      <div class="col-12">
        <div class="success-header">
          <img src="../../../assets/img/sun.png" alt="Солнце">
          <p class="header-1">Замовлення оплачене!</p>
          <p class="header-2">Статус доставки ви можете перевірити в онлайн режимі у додатку Нова пошта,<br> як тільки
            набір буде сформовано та передано на відправку</p>
        </div>
        <div class="success-body row">
          <div class="col-md-4 p-0 m-t-mobile">
            <div class="circle-number">
              1
            </div>
            <div class="step-success">
              Продуктовий<br>набір
            </div>
            <div class="step-content">
              №{{purchase.setId}}
            </div>
          </div>
          <div class="col-md-4 p-0 m-t-mobile">
            <div class="circle-number">
              2
            </div>
            <div class="step-success">
              Буде<br>доставлено
            </div>
            <div class="step-content">
              {{getDeliveryDate(purchase.deliveryDate)}}
            </div>
          </div>
          <div class="col-md-4 p-0 m-t-mobile">
            <div class="circle-number">
              3
            </div>
            <div class="step-success">
              Кур’єром Нова<br>пошта за адресою
            </div>
            <div class="step-content">
              <div>
                <span *ngIf="purchase.recipientCityName">{{purchase.recipientCityName}},&nbsp;</span>
                <span *ngIf="purchase.recipientStreetTypeName">{{purchase.recipientStreetTypeName}}&nbsp;</span>
                <span *ngIf="purchase.recipientStreetName">{{purchase.recipientStreetName}}</span>
                <span *ngIf="purchase.recipientHouse">,&nbsp;{{purchase.recipientHouse}}</span>
                <span *ngIf="purchase.recipientFlat">,&nbsp;кв. {{purchase.recipientFlat}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
