import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

import {UserService} from '../../services/user.service';
import {FoodsetsService} from '../../services/foodsets.service';

import {ModalContentComponent} from '../modal/modal.component';
import {WindowService} from '../../services/window.sevice';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  private modalRef: BsModalRef;
  private minNumbersOfSets = 1;
  private maxNumbersOfSets = 3;
  public numberOfSets = {
    1: {
      prev: 'disabled',
      value: '1',
      maxValue: this.maxNumbersOfSets,
      next: ''
    },
    2: {
      prev: 'disabled',
      value: '1',
      maxValue: this.maxNumbersOfSets,
      next: ''
    },
    3: {
      prev: 'disabled',
      value: '1',
      maxValue: this.maxNumbersOfSets,
      next: ''
    }
  };

  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: BsModalService,
    private foodsetsService: FoodsetsService,
    private windowService: WindowService
  ) {
    this.foodsetsService.setFooterWhite(false);
  }

  ngOnInit(): void {
  }

  public decreaseNumberOfSets(order: string) {
    if (this.numberOfSets[order].value <= 1) {
      return;
    }

    this.numberOfSets[order].value--;
  }

  public increaseNumberOfSets(order: string) {
    if (this.numberOfSets[order].value >= this.numberOfSets[order].maxValue) {
      return;
    }

    this.windowService.nativeWindow.ninjaClickTrigger('foodsets_add_more', 'set_' + order);
    this.numberOfSets[order].value++;
  }

  public checkValue(order: string) {
    this.numberOfSets[order].value = +this.numberOfSets[order].value;

    if (this.numberOfSets[order].value === 0 || !this.numberOfSets[order].value || this.numberOfSets[order].value < 1) {
      setTimeout(() => {
        this.numberOfSets[order].value = this.minNumbersOfSets;
      });
    } else if (this.numberOfSets[order].value > this.numberOfSets[order].maxValue) {
      setTimeout(() => {
        this.numberOfSets[order].value = this.numberOfSets[order].maxValue;
      });
    }

    setTimeout(() => {
      const numberOfSet = +this.numberOfSets[order].value;

      this.numberOfSets[order].prev = '';
      this.numberOfSets[order].next = '';
      if (numberOfSet <= 1) {
        this.numberOfSets[order].prev = 'disabled';
      }

      if (numberOfSet >= this.numberOfSets[order].maxValue) {
        this.numberOfSets[order].next = 'disabled';
      }
    });
  }

  public openSetPage(e, setId) {
    e.preventDefault();

    if (this.userService.isUserLogged()) {
      this.windowService.nativeWindow.ninjaClickTrigger('foodsets_buy_click', 'set_' + setId);
      // Go to foodset page with query parameter of product set number
      this.router.navigate(['foodset/' + setId], {
        queryParams: {
          number: this.numberOfSets[setId].value
        },
        queryParamsHandling: 'merge'
      }).then(() => {
        window.scrollTo(0, 0);
      });
    } else {
      const initialState = {
        modalContent: 'Для продовження необхідно авторизуватись за допомогою сервісу OLX',
        title: 'На жаль ви не авторизовані',
        alertStatus: 'alert-warning',
        showLoginBtn: true,
        setId,
        setCount: this.numberOfSets[setId].value
      };

      this.modalRef = this.modalService.show(ModalContentComponent, {initialState});
    }
  }

  public getSetPrice(fsid: string) {
    return this.foodsetsService.getSetPrice(fsid);
  }

}
