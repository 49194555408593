import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

import {ModalContentComponent} from '../components/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
    private router: Router
  ) {
  }

  public handle(error: string, redirect?: boolean, reloadPage?: boolean) {
    const initialState = {
      modalContent: error,
      title: 'Упс, щось пійшло не так',
      alertStatus: 'alert-danger',
      reloadPage
    };
    if (redirect) {
      this.router.navigate(['/']).then(() => {
        this.modalRef = this.modalService.show(ModalContentComponent, {initialState});
      });
    } else {
      this.modalRef = this.modalService.show(ModalContentComponent, {initialState});
    }
  }
}
