import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public $userData: Subject<any> = new Subject<any>();
  public userLogged = false;

  constructor() {
  }

  public setUserData(data): void {
    this.$userData.next(data);
    this.userLogged = !this.isEmptyUserData(data);
  }

  public isUserLogged(): boolean {
    return this.userLogged;
  }

  public userDataSubscription(): Observable<any> {
    return this.$userData.asObservable();
  }

  public isEmptyUserData(obj): boolean {
    for (const i in obj) {
      if (obj.hasOwnProperty(i)) {
        return false;
      }
    }
    return true;
  }
}
