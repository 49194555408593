import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-modal-content',
  template: `
    <div class="modal-body" *ngIf="modalContent">
      <iframe [srcdoc]='getIframeSrc()' class="app-confirm3ds" frameborder="0" width="100%" name="3dsFrame"></iframe>
    </div>
  `,
  styles: ['.app-confirm3ds { position: relative; width: 100%; height: 410px; overflow: auto; }']
})

export class TreeDsComponent implements OnInit {
  modalContent: any;

  constructor(
    public sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
  }

  public getIframeSrc() {
    return this.sanitizer.bypassSecurityTrustHtml(this.modalContent);
  }
}
