import {Injectable} from '@angular/core';
import {BsModalService, BsModalRef, ModalOptions} from 'ngx-bootstrap/modal';

import {TreeDsComponent} from '../components/modal/3dsModal.component';

@Injectable({
  providedIn: 'root'
})
export class ThreeDsService {
  modalRef: BsModalRef;

  constructor(
    private modalService: BsModalService
  ) {
  }

  public open3Ds(data: string) {
    const config: ModalOptions = {
      backdrop  : 'static',
      keyboard  : false,
      ignoreBackdropClick: true,
      initialState: {
        modalContent: data
      }
    };
    this.modalRef = this.modalService.show(TreeDsComponent, config);
  }

  public close3Ds() {
    this.modalRef.hide();
  }
}
