import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  public purchaseData;

  constructor() {
  }

  public setPurchase(data) {
    this.purchaseData = data;
  }

  public getPurchase() {
    return this.purchaseData;
  }
}
